const setupNewsletter = () => {
  const newsletter = document.querySelector('#newsletter');

  if (!newsletter) return;

  const createContact = (email) => {
    const options = {
      method: 'POST',
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        'api-key':
          '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',
      },

      body: JSON.stringify({
        email: email,
      }),
    };

    fetch('https://api.brevo.com/v3/contacts', options)
      .then((response) => response.json())
      .then((response) => {
        if (response.code === 'duplicate_parameter') {
          alert('Vous êtes déjà inscrit à la newsletter');
        } else {
          fetch('https://api.brevo.com/v3/contacts/lists/5/contacts/add', {
            method: 'POST',
            headers: {
              accept: 'application/json',
              'Content-Type': 'application/json',
              'api-key':
                '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',
            },
            body: JSON.stringify({
              emails: [email],
            }),
          })
            .then((responsebis) => responsebis.json())
            .then((responsebis) => {
              console.log(responsebis);
            });

          const h3 = document.querySelector('#newsletter h3');
          h3.textContent = 'Merci pour votre inscription !';
          newsletter.setAttribute('data-success', true);
        }
      })
      .catch((err) => console.error(err));
  };

  const togglerNewsletter = document.querySelector('#newsletter-toggler');

  const email = document.querySelector('#email');

  const regex = /\S+@\S+\.\S+/;

  newsletter.addEventListener('submit', (e) => {
    e.preventDefault();

    if (!regex.test(email.value)) {
      alert('Merci de renseigner un email valide');
      return;
    }

    createContact(email.value);
  });

  togglerNewsletter.addEventListener('click', () => {
    newsletter.setAttribute('aria-hidden', true);
  });
};

setupNewsletter();
